<template>
    <Guest :title="t('register2')">
        <div class="py-8">
            <form v-on:submit.prevent="navigateNext">
                <TextInput v-model:value="first_name" v-model:errors="errors.first_name" :label="t('first_name_label')" :placeholder="t('first_name_placeholder')" />
                <TextInput v-model:value="last_name" v-model:errors="errors.last_name" :label="t('last_name_label')" :placeholder="t('last_name_placeholder')" class="mt-4"/>

                <div class="mt-8">
                    <button type="submit" class="button w-full">
                        {{ t('next_step') }}
                    </button>
                </div>
            </form>
        </div>
    </Guest>
</template>

<script>
import router from "@/router";
import Guest from "@/wrappers/Guest";
import TextInput from "@/components/forms/TextInput";
import { register_form_to_storage } from "@/helpers/storage/RegisterFormHelper";
import { register_validation } from "@/helpers/api/RegistrationHelper";
import { setLoading } from "@/helpers/storage/loadingSpinnerHelper";
import messages from "@/helpers/storage/MessageHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "Register2",
    components:{
        Guest,
        TextInput
    },
    setup() {
        return useI18n({
            viewPrefix: "Register"
        });
    },
    computed:{
        first_name: register_form_to_storage('first_name'),
        last_name: register_form_to_storage('last_name'),
    },
    data(){
        return {
            errors: {
                first_name: [],
                last_name: [],
            }
        }
    },
    methods: {
        navigateNext() {
            setLoading(true);

            const data = {
                first_name: this.first_name, 
                last_name: this.last_name, 
            }

            register_validation(data)
                .then((result)=> {
                    if (result.success){
                        router.push({name: "register.3"});
                    } else {
                        this.errors = result.errors;
                    }
                })
                .catch((error) => {
                    messages.error(this.t('messages.something_went_wrong', {error: error.message}));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
}
</script>

<style scoped>

</style>
